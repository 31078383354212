<template>
	<AssistantComponent />
</template>

<script>
import AssistantComponent from '@/components/assistant/Index';

export default {
	name: 'TopicAssistant',
	components: { AssistantComponent },
};
</script>
